import { FunctionComponent } from "react";
import { FormFieldProps } from "./FormFieldProps";
import { useController } from "react-hook-form";
import {
  BusinessRolesSelect,
  BusinessRolesSelectProps
} from "../../pickers/BusinessRolesSelect";

type FormBusinessRolesSelectProps = Omit<
  BusinessRolesSelectProps,
  "selectedKeys" | "onChangeSelectedKeys"
> &
  FormFieldProps;

export const FormBusinessRolesSelect: FunctionComponent<FormBusinessRolesSelectProps> = ({
  name,
  rules,
  ...restBusinessSelectProps
}) => {
  const {
    field: { value, onChange, ref, ...fieldProps }
  } = useController({ name, rules });

  return (
    <BusinessRolesSelect
      selectedKeys={value}
      onChangeSelectedKeys={onChange}
      {...restBusinessSelectProps}
      {...fieldProps}
    />
  );
};
