import { useController } from "react-hook-form";

import {
  ButtonsGroupChoice,
  ButtonsGroupChoiceProps,
  FieldItem,
  FieldItemStyles
} from "@bps/fluent-ui";
import { FormFieldProps } from "./FormFieldProps";

type FormButtonsGroupSingleChoiceProps<T> = Omit<
  ButtonsGroupChoiceProps<T>,
  "name" | "value" | "onChange" | "theme"
> &
  FormFieldProps & {
    label?: string;
    hint?: string;
  } & { fieldItemStyles?: Partial<FieldItemStyles> };

export const FormButtonsGroupSingleChoice = (
  props: FormButtonsGroupSingleChoiceProps<any>
) => {
  const {
    name,
    label,
    options,
    rules,
    hint,
    fieldItemStyles,
    ...buttonGroupSingleChoiceProps
  } = props;

  const {
    field: { value: currentValue, onChange, ...fieldProps }
  } = useController({
    name,
    rules
  });

  const handleChange = (selectedKey: any) => {
    onChange(selectedKey);
  };

  return (
    <FieldItem name={name} label={label} hint={hint} styles={fieldItemStyles}>
      <ButtonsGroupChoice
        options={options}
        value={currentValue}
        onChange={handleChange}
        {...buttonGroupSingleChoiceProps}
        {...fieldProps}
      />
    </FieldItem>
  );
};
