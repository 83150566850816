import {
  IMessageBarStyles,
  Link,
  MessageBar,
  MessageBarType,
  Stack,
  Text
} from "@bps/fluent-ui";
import { FunctionComponent } from "react";
import { getErrorAlertMessage } from "@libs/api/types/error.utils";

interface ErrorAlertProps {
  error?: unknown;
  reload?: boolean;
  styles?: IMessageBarStyles;
}

export const ErrorAlert: FunctionComponent<ErrorAlertProps> = ({
  error,
  reload,
  styles
}) => {
  if (!error) return null;
  return (
    <MessageBar messageBarType={MessageBarType.error} styles={styles}>
      <Stack horizontal tokens={{ childrenGap: 16 }}>
        <Text>{getErrorAlertMessage(error)}</Text>
        {reload && <Link onClick={window.location.reload}>Reload</Link>}
      </Stack>
    </MessageBar>
  );
};
