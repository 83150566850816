import React from "react";

import { Dropdown, DropdownProps } from "@bps/fluent-ui";
import { siteComponents } from "@libs/api/gateways/sia/sia-ops-gateway.utils";

const componentOptions = siteComponents.map(sc => ({
  key: sc.type,
  text: sc.name
}));

interface SiteComponentDropdownProps
  extends Omit<DropdownProps, "options" | "name"> {}

export const SiteComponentDropdown: React.FC<SiteComponentDropdownProps> = ({
  ...props
}) => {
  return (
    <Dropdown
      {...props}
      options={componentOptions}
      name="site-component-dropdown"
    />
  );
};
