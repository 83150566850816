import { FormFieldProps } from "@components/form/fields/FormFieldProps";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  FieldItem,
  mergeStyles,
  TimePicker,
  TimePickerProps,
  useTheme
} from "@bps/fluent-ui";

// DatePickerProps has a 'name' property but it's optional; for usage with react-hook-form it needs to be mandatory.
type FormTimePickerProps = Omit<
  TimePickerProps,
  "name" | "value" | "onChange"
> &
  FormFieldProps & { disabled?: boolean };

export const FormTimePicker: React.FC<FormTimePickerProps> = ({
  name,
  rules,
  disabled,
  ...props
}) => {
  const { control, formState } = useFormContext();
  const isDisabled = disabled || formState.isSubmitting;
  const theme = useTheme();

  const hasErrorClassName = useMemo(
    () =>
      mergeStyles({
        "& .ms-TextField-fieldGroup": {
          borderColor: theme.semanticColors.errorText
        }
      }),
    [theme]
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { value, onChange, ref, ...fieldProps },
        fieldState: { error }
      }) => (
        <FieldItem name={name} errorMessage={error?.message}>
          <TimePicker
            {...props}
            {...fieldProps}
            value={value}
            onChange={value => {
              onChange(value);
            }}
            disabled={isDisabled}
            className={error?.message && hasErrorClassName}
          />
        </FieldItem>
      )}
    />
  );
};
