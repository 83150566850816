import { CopyToClipboardButton } from "@components/buttons/CopyToClipboardButton";
import { DisplayGuid } from "@components/DisplayGuid";
import { guid } from "@libs/common/guid";

import { ITextProps, Stack, Text, TextProps, useTheme } from "@bps/fluent-ui";

export interface CopyableTextProps extends ITextProps {
  clipboardContent?: string;
  horizontalFill?: boolean;
}

export const CopyableText = ({
  clipboardContent,
  horizontalFill,
  children,
  ...textProps
}: CopyableTextProps) => {
  const theme = useTheme();
  if (
    !(
      clipboardContent ||
      typeof children === "string" ||
      typeof children === "object"
    )
  ) {
    throw new Error(
      "The `clipboardContent` property must be provided if the children property is not a string"
    );
  }

  const stackStyles = {
    root: {
      display: "inline-flex",
      width: horizontalFill ? "100%" : undefined
    }
  };

  return children ? (
    <Stack
      horizontal
      verticalAlign="center"
      tokens={{ childrenGap: theme.spacing.s2 }}
      styles={stackStyles}
    >
      <Text {...textProps}>{children}</Text>
      <CopyToClipboardButton
        clipboardContent={clipboardContent ?? (children as string)}
      />
    </Stack>
  ) : (
    <></>
  );
};

export type CopyableGuidProps = Omit<TextProps, "children"> & {
  short?: boolean;
  value: guid;
};

export const CopyableGuid = ({
  short,
  value,
  ...textProps
}: CopyableGuidProps) => {
  return value ? (
    <CopyableText clipboardContent={value} {...textProps}>
      <DisplayGuid short={short} {...textProps}>
        {value}
      </DisplayGuid>
    </CopyableText>
  ) : (
    <></>
  );
};
