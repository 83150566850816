import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";
import { PropsWithChildren } from "react";

import { Spinner } from "@bps/fluent-ui";

import { NoPermissionError } from "./errors/NoPermissionError";

export interface SecurableComponentProps {
  permissions?: Permissions | Permissions[];
  permissionsOperator?: "and" | "or";
  showError?: boolean;
}

export const SecurableComponent = (
  props: PropsWithChildren<SecurableComponentProps>
) => {
  const {
    permissions,
    permissionsOperator,
    children,
    showError = true
  } = props;

  const { data: hasPermissions, isLoading } = usePermissionsCheckQuery(
    permissions,
    permissionsOperator
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (hasPermissions) {
    return <>{children}</>;
  }
  if (showError) {
    return <NoPermissionError />;
  }

  return null;
};
