import React, { useState } from "react";

import {
  DetailsRow,
  FontIcon,
  IDetailsRowProps,
  mergeStyles,
  mergeStyleSets,
  Stack,
  useTheme
} from "@bps/fluent-ui";

interface DetailsRowExpandProps {
  row?: IDetailsRowProps;
  expand: React.ReactNode;
  border?: boolean;
  useExpandArrow?: boolean;
}

export const DetailsRowExpand: React.FC<DetailsRowExpandProps> = ({
  row,
  expand,
  useExpandArrow,
  border = true
}) => {
  const theme = useTheme();
  const [active, setActive] = useState<boolean>(false);

  return (
    <Stack>
      <Stack
        onClick={() => (!useExpandArrow ? setActive(active => !active) : null)}
        styles={{
          root: {
            "&:hover": {
              background: theme.semanticColors.listItemBackgroundHovered,
              cursor: "pointer"
            }
          }
        }}
      >
        {row && (
          <DetailsRow
            {...row}
            styles={mergeStyleSets(
              {
                root: {
                  borderBottomColor:
                    border && active ? "transparent" : undefined,
                  background: active
                    ? theme.semanticColors.listItemBackgroundChecked
                    : "transparent",
                  cursor: "pointer"
                }
              },
              row.styles
            )}
          />
        )}
        {useExpandArrow && (
          <FontIcon
            iconName={active ? "ChevronUp" : "ChevronDown"}
            onClick={() => setActive(active => !active)}
            className={mergeStyles({
              maxWidth: 20,
              position: "absolute", // Absolute positioning prevents recalculation of the DetailsRow width
              right: 20,
              marginTop: 16
            })}
          />
        )}
      </Stack>

      {active && (
        <Stack
          styles={{
            root: border
              ? {
                  borderStyle: "solid",
                  borderColor: theme.semanticColors.listItemBackgroundChecked,
                  borderWidth: 2,
                  marginBottom: theme.spacing.s2
                }
              : {}
          }}
        >
          {expand}
        </Stack>
      )}
    </Stack>
  );
};
