import { ErrorAlert } from "./errors/ErrorAlert";
import { ReactNode } from "react";
import { UseQueryResult } from "react-query";

import { CenteredLargeSpinner, NoDataTile } from "@bps/fluent-ui";

export interface QueryStateIndicatorProps<TData = unknown> {
  isLoading: boolean;
  loadingTitle?: string;
  error?: Error | unknown;
  data?: TData;
  children?: ReactNode | ((data: TData) => ReactNode);
  noDataText?: string;
  /** Should a null/undefined data value be allowed as valid data? */
  allowNullOrUndefined?: boolean;
  LoadingComponent?: ReactNode;
  NoDataNode?: ReactNode;
}

export function QueryStateIndicator<TData extends unknown>({
  isLoading,
  loadingTitle,
  error,
  data,
  allowNullOrUndefined,
  children,
  noDataText,
  LoadingComponent,
  NoDataNode
}: QueryStateIndicatorProps<TData>) {
  if (isLoading) {
    return LoadingComponent ? (
      <>{LoadingComponent}</>
    ) : (
      <CenteredLargeSpinner
        label={loadingTitle || "Loading, please wait..."}
        labelPosition="top"
      />
    );
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  if (
    !allowNullOrUndefined &&
    (!data || (Array.isArray(data) && !data.length))
  ) {
    return NoDataNode ? (
      <>{NoDataNode}</>
    ) : (
      <NoDataTile
        styles={{ root: { flexGrow: 1 } }}
        textProps={{
          text: noDataText ?? "No data was returned from the query"
        }}
        linkProps={{}}
        showBoxShadow={false}
      />
    );
  }

  if (typeof children === "function") {
    return children(data as TData);
  }

  return <>{children}</>;
}

type CombinableQuery = {
  [key: string]: UseQueryResult;
};

type CombinedQuery = {
  data: { [key: string]: unknown };
  isLoading: boolean;
  error?: unknown;
};

export const combineQueries = (queries: CombinableQuery): CombinedQuery => {
  return Object.keys(queries).reduce(
    (memo, key) => {
      const { data, isLoading, error } = queries[key];

      return {
        data: {
          ...memo.data,
          [key]: data
        },
        isLoading: memo.isLoading || isLoading,
        error: memo.error || error
      };
    },
    {
      data: {},
      isLoading: false,
      error: undefined
    } as CombinedQuery
  );
};
