import { useState } from "react";

export const useExpandedRows = () => {
  const [expandedIds, setExpandedIds] = useState<Set<string>>(
    new Set<string>()
  );

  const toggleExpandedIds = (id: string) => {
    setExpandedIds(prev =>
      prev.has(id)
        ? new Set([...prev].filter(x => x !== id))
        : new Set(prev.add(id))
    );
  };

  return { expandedIds, toggleExpandedIds };
};
