import React, { FunctionComponent } from "react";
import {
  DetailsRow,
  IDetailsRowProps,
  mergeStyles,
  useTheme
} from "@bps/fluent-ui";

interface ExpandableRowProps {
  rowProps: IDetailsRowProps;
  id: string;
  toggleExpandedIds: (id: string) => void;
  expandedIds: Set<string>;
  ExpandableComponent: React.ReactNode;
}

export const ExpandableRow: FunctionComponent<ExpandableRowProps> = ({
  toggleExpandedIds,
  expandedIds,
  rowProps,
  id,
  ExpandableComponent
}) => {
  const theme = useTheme();

  const expandableComponentStyles = mergeStyles({
    border: "1px solid",
    borderColor: theme.palette.neutralQuaternaryAlt,
    borderTop: "none",
    padding: 8
  });
  return (
    <div>
      <div onClick={() => toggleExpandedIds(id)}>
        <DetailsRow
          {...rowProps}
          styles={{
            fields: {
              cursor: "pointer",
              background: expandedIds.has(id)
                ? theme.palette.neutralQuaternaryAlt
                : "initial",

              ":hover": {
                background: theme.palette.neutralQuaternaryAlt
              }
            }
          }}
        />
      </div>

      {expandedIds.has(id) && (
        <div className={expandableComponentStyles}>{ExpandableComponent}</div>
      )}
    </div>
  );
};
