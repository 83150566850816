import { FormFieldProps } from "@components/form/fields/FormFieldProps";
import React, { useState } from "react";
import { useController } from "react-hook-form";
import {
  FieldItem,
  GenericSelect,
  GenericSelectProps,
  Option,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { useGateways } from "@libs/api/gateways-context";

type FormAdUserSearchProps = Omit<FormFieldProps, "ref"> &
  Omit<
    GenericSelectProps,
    | "theme"
    | "options"
    | "searchBoxProps"
    | "selectedKeys"
    | "onChangeSelectedKeys"
  > & {};

export const FormAdUserSearch: React.FC<FormAdUserSearchProps> = ({
  name,
  rules,
  disabled,
  ...props
}) => {
  const { envGateway } = useGateways();

  const theme = useTheme();

  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    field: { value, onChange, ref, ...fieldProps },
    fieldState: { error },
    formState
  } = useController({
    name,
    rules
  });

  const onSearch = async (value: string | undefined) => {
    if (!value) {
      return;
    }
    setLoading(true);
    const users = await envGateway.searchUsers(value);
    setOptions(
      users.length
        ? users.map(user => ({ key: user.id, id: user.id, text: user.name }))
        : []
    );
    setLoading(false);
  };

  return (
    <>
      <FieldItem name={name} errorMessage={error?.message}>
        <GenericSelect
          loading={loading}
          options={options}
          searchBoxProps={{
            onSearch
          }}
          selectedKeys={value || []}
          multiSelect={false}
          onChangeSelectedKeys={onChange}
          disabled={disabled || formState.isSubmitting}
          styles={{
            field: {
              justifyContent: "space-between",
              borderColor: error ? theme.semanticColors.errorText : undefined
            }
          }}
          onRenderOption={(option: Option) => <Text>{option.text}</Text>}
          {...fieldProps}
          {...props}
        />
      </FieldItem>
    </>
  );
};
