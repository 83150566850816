import { FunctionComponent } from "react";
import { FormFieldProps } from "./FormFieldProps";
import { useController } from "react-hook-form";
import {
  SecurityRolesSelect,
  SecurityRolesSelectProps
} from "../../pickers/SecurityRolesSelect";

type FormSecurityRolesSelectProps = Omit<
  SecurityRolesSelectProps,
  "selectedKeys" | "onChangeSelectedKeys"
> &
  FormFieldProps;

export const FormSecurityRolesSelect: FunctionComponent<FormSecurityRolesSelectProps> = ({
  name,
  rules,
  ...restSecurityRolesSelectProps
}) => {
  const {
    field: { value, onChange, ref, ...fieldProps }
  } = useController({ name, rules });

  return (
    <SecurityRolesSelect
      selectedKeys={value}
      onChangeSelectedKeys={onChange}
      {...restSecurityRolesSelectProps}
      {...fieldProps}
    />
  );
};
