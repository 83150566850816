import { IToggleProps, Toggle } from "@bps/fluent-ui";
import { Controller, useFormContext } from "react-hook-form";
import { FormFieldProps } from "@components/form/fields/FormFieldProps";

// IToggleProps has a 'name' property but it's optional; for usage with react-hook-form it needs to be mandatory.
type FormSwitchProps = Omit<IToggleProps, "name" | "automationAttribute"> &
  FormFieldProps;

export const FormSwitch = (props: FormSwitchProps) => {
  const { name, disabled, rules, ...toggleProps } = props;

  const { control, formState } = useFormContext();
  const isDisabled = disabled || formState.isSubmitting;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange, ref, ...fieldProps } }) => (
        <Toggle
          {...toggleProps}
          {...fieldProps}
          automationAttribute={name}
          checked={value}
          onChange={(_, option) => {
            onChange(option);
          }}
          disabled={isDisabled}
        />
      )}
    />
  );
};
