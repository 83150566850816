import { FunctionComponent } from "react";

import { useFieldContext, useFieldRegister } from "@bps/fluent-ui";
import { TenantPicker, TenantPickerProps } from "../../pickers/TenantPicker";

export interface TenantsSelectFilterItemProps
  extends Omit<
    TenantPickerProps,
    "selectedKeys" | "onChangeSelectedKeys" | "onFocus" | "onBlur"
  > {
  name: string;
}
export const TenantsSelectFilterItem: FunctionComponent<TenantsSelectFilterItemProps> = ({
  name,
  ...usersSelectProps
}) => {
  useFieldRegister(name);

  const { value, setFieldValue, focusField, blurField } = useFieldContext<
    string | undefined | string[]
  >(name);

  return (
    <TenantPicker
      {...usersSelectProps}
      onFocus={focusField}
      onBlur={blurField}
      selectedKeys={value as any}
      onChangeSelectedKeys={setFieldValue}
    />
  );
};
