import { useController } from "react-hook-form";

import {
  FieldItem,
  FieldItemProps,
  FieldItemStyles,
  mergeStyleSets,
  SpinNumberInput,
  SpinNumberInputProps
} from "@bps/fluent-ui";
import { FormFieldProps } from "./FormFieldProps";

type FormSpinNumberInputProps = Omit<
  SpinNumberInputProps,
  "value" | "onChange"
> &
  Pick<FieldItemProps, "suffix" | "required" | "label" | "hint"> & {
    filedItemStyles?: Partial<FieldItemStyles>;
  } & FormFieldProps;

export const FormSpinNumberInput = (props: FormSpinNumberInputProps) => {
  const {
    name,
    disabled,
    rules,
    suffix,
    label,
    filedItemStyles,
    required,
    hint,
    ...spinNumberInputProps
  } = props;

  const {
    field: { value, onChange },
    fieldState
  } = useController({ name, rules });

  return (
    <FieldItem
      name={name}
      label={label}
      required={required}
      errorMessage={fieldState.error?.message}
      disabled={disabled}
      suffix={suffix}
      hint={hint}
      styles={mergeStyleSets(
        {
          itemWrapper: { alignItems: "baseline" }
        },
        filedItemStyles
      )}
    >
      <SpinNumberInput
        {...spinNumberInputProps}
        onChange={onChange}
        disabled={disabled}
        value={value}
      />
    </FieldItem>
  );
};
