import { MouseEvent, useMemo, useState } from "react";

import {
  ContextualMenu,
  ContextualMenuItemType,
  FontIcon,
  IColumn,
  IContextualMenuItem,
  IContextualMenuProps,
  IDetailsColumnProps,
  IDetailsListProps,
  ITheme,
  mergeStyleSets,
  ShimmeredDetailsListProps,
  Stack,
  useTheme
} from "@bps/fluent-ui";
import { DateTime } from "@bps/utils";

import { ShimmeredDetailsList } from "./ShimmeredDetailsList";

export type DataTableColumn<TData> = Omit<IColumn, "minWidth"> & {
  sort?: boolean | ((left: TData, right: TData) => number);
  width?: IColumn["minWidth"];
  minWidth?: IColumn["minWidth"];
  filterable?: boolean;
  filter?: string[] | Set<string>;
};

type Props<TData> = Omit<
  ShimmeredDetailsListProps,
  "items" | "columns" | "styles"
> & {
  items: TData[] | undefined;
  columns: DataTableColumn<TData>[];
  rightAlignColumns?: boolean;
  styles?: IDetailsListProps["styles"];
};

const createDefaultSort = (fieldName: string) => (
  left: unknown,
  right: unknown
): number => {
  const leftValue = (left as { [key: string]: unknown })[fieldName];
  const rightValue = (right as { [key: string]: unknown })[fieldName];

  if (leftValue == null && rightValue == null) return 0;
  if (leftValue == null) return 1;
  if (rightValue == null) return -1;

  if (leftValue instanceof DateTime && rightValue instanceof DateTime) {
    return leftValue.valueOf() - rightValue.valueOf();
  }

  if (typeof leftValue === "number" && typeof rightValue === "number") {
    return leftValue - rightValue;
  }

  if (typeof leftValue === "boolean" && typeof rightValue === "boolean") {
    return leftValue ? -1 : 1;
  }

  return (leftValue as object)
    .toString()
    .localeCompare((rightValue as object).toString());
};

interface FilterContextMenuTargetProps {
  target: IContextualMenuProps["target"];
  key: string;
}

interface SortColumn {
  key: string;
  isDescending: boolean;
}

interface FilterContextMenuProps {
  target: IContextualMenuProps["target"];
  options: string[];
  filters?: Set<string>;
  onFiltersChanged: (filters: Set<string>) => void;
  onClose: () => void;
}

const FilterContextMenu = ({
  target,
  options,
  filters,
  onFiltersChanged,
  onClose
}: FilterContextMenuProps) => {
  const handleClick = (event: MouseEvent, item: IContextualMenuItem) => {
    event.preventDefault();

    const nextFilters = new Set(filters || []);
    if (!nextFilters.delete(item.data)) {
      nextFilters.add(item.data);
    }

    onFiltersChanged(nextFilters);
  };

  const items = options.map(
    value =>
      ({
        key: value,
        data: value,
        text: value,
        canCheck: true,
        checked: filters && filters.has(value),
        onClick: handleClick
      } as IContextualMenuItem)
  );

  items.unshift({
    key: "header",
    itemType: ContextualMenuItemType.Header,
    text: "Filter items"
  });

  return <ContextualMenu target={target} items={items} onDismiss={onClose} />;
};

export const DataTable = <TData extends unknown>({
  items: initialItems = [],
  columns: initialColumns,
  rightAlignColumns,
  styles,
  ...detailsListProps
}: Props<TData>) => {
  const theme = useTheme();
  const [filters, setFilters] = useState<Map<string, Set<string>>>(() =>
    getInitialFilters(initialColumns)
  );

  const [sortColumn, setSortColumn] = useState<SortColumn | undefined>(() =>
    getInitialSortColumn(initialColumns)
  );

  const [contextMenu, setContextMenu] = useState<
    FilterContextMenuTargetProps | undefined
  >(undefined);

  const handleFilterChanged = (key: string) => (next: Set<string>) =>
    setFilters(prev => new Map(prev).set(key, next));

  const handleFilterMenuClose = () => setContextMenu(undefined);

  const handleColumnHeaderClick = (_, clickedColumn: IColumn) => {
    const column = clickedColumn as DataTableColumn<TData>;
    if (!column?.sort) return;

    setSortColumn(updateSortColumn(clickedColumn.key));
  };

  const columns = useMemo(() => {
    return initialColumns.map(
      (
        { key, width, minWidth, maxWidth, filter, filterable, ...column },
        index
      ) => {
        const columnFilters = filters.get(key);

        const isFilterable = columnFilters != null;
        const isFiltered = Boolean(columnFilters && columnFilters.size > 0);

        return {
          ...column,

          key,
          styles:
            rightAlignColumns && index !== 0
              ? { cellTitle: { justifyContent: "flex-end" } }
              : undefined,
          fieldName: key,
          minWidth: minWidth ?? width ?? 140,
          maxWidth: maxWidth ?? width,
          isSorted: sortColumn?.key === key,
          isSortedDescending:
            sortColumn?.key === key && sortColumn.isDescending,
          onRenderHeader: renderColumnHeader(isFilterable, isFiltered, theme),

          onColumnContextMenu: (_, event: MouseEvent<HTMLElement>) => {
            if (!isFilterable) {
              setContextMenu(undefined);
              return;
            }

            setContextMenu({
              key,
              target: event.currentTarget
            });
          }
        } as IColumn;
      }
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialColumns, sortColumn, filters]);

  const data = useMemo(() => {
    // Don't try and filter or sort an empty data set
    if (!initialItems || initialItems.length === 0) return [];

    // If initialItems is not an array, we don't want to try and operate on it like an array
    if (!Array.isArray(initialItems)) return initialItems;

    const filter = createFilter(filters);
    const sort = createSort(initialColumns, sortColumn);

    return initialItems.filter(filter).sort(sort);
  }, [initialItems, initialColumns, sortColumn, filters]);

  return (
    <>
      <ShimmeredDetailsList
        items={data}
        columns={columns}
        detailsListStyles={styles}
        onColumnHeaderClick={handleColumnHeaderClick}
        onRenderRow={
          rightAlignColumns
            ? (props, defaultRender) => {
                if (props && defaultRender) {
                  return defaultRender({
                    ...props,
                    styles: mergeStyleSets(props.styles, {
                      cell: {
                        "&:not(:first-child)": {
                          textAlign: "right"
                        }
                      }
                    })
                  });
                }
                return null;
              }
            : undefined
        }
        {...detailsListProps}
      />

      {contextMenu && (
        <FilterContextMenu
          target={contextMenu.target}
          options={getFilterItems(initialItems ?? [], contextMenu.key)}
          filters={filters.get(contextMenu.key)}
          onFiltersChanged={handleFilterChanged(contextMenu.key)}
          onClose={handleFilterMenuClose}
        />
      )}
    </>
  );
};

const getInitialSortColumn = (
  columns: DataTableColumn<unknown>[]
): SortColumn | undefined => {
  const sortColumn = columns.find(c => c.isSorted);

  return sortColumn
    ? { key: sortColumn.key, isDescending: !!sortColumn.isSortedDescending }
    : undefined;
};

const getInitialFilters = (
  columns: DataTableColumn<unknown>[]
): Map<string, Set<string>> => {
  return columns.reduce((map, column) => {
    if (!(column.filterable || column.filter)) return map;

    return map.set(column.key, new Set(column.filter ?? []));
  }, new Map<string, Set<string>>());
};

const updateSortColumn = (nextKey: string) => {
  return ({ key, isDescending }: SortColumn) => {
    if (key === nextKey) {
      return { key, isDescending: !isDescending };
    }

    return { key: nextKey, isDescending: false };
  };
};

const renderColumnHeader = (
  filterable: boolean | undefined,
  isFiltered: boolean,
  theme: ITheme
) => {
  return (
    props?: IDetailsColumnProps,
    defaultRender?: (props?: IDetailsColumnProps) => JSX.Element | null
  ) => {
    return (
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{ childrenGap: theme.spacing.s1 }}
      >
        <span>{defaultRender!(props)}</span>
        {filterable && (
          <FontIcon
            iconName="Filter"
            styles={{
              root: {
                color: isFiltered
                  ? theme.palette.themeDarker
                  : theme.semanticColors.disabledBorder
              }
            }}
          />
        )}
      </Stack>
    );
  };
};

function getFilterItems<TData>(data: TData[], fieldName: string) {
  const values = data.reduce(
    (memo, item) => memo.add(item[fieldName]?.toString()),
    new Set<string>()
  );

  return [...values].sort((left: string, right: string) =>
    left.localeCompare(right)
  );
}

function createFilter<TData>(filters: Map<string, Set<string>>) {
  return (item: TData) => {
    for (const [key, values] of filters) {
      if (!values?.size) continue;

      const itemValue = item[key];
      if (!values.has(itemValue)) return false;
    }

    return true;
  };
}

function createSort(
  columns: DataTableColumn<unknown>[],
  sortColumn?: SortColumn
) {
  if (!sortColumn) return () => 0;

  const sortColumnDef = columns.find(c => c.key === sortColumn.key)!;
  const sortFn =
    typeof sortColumnDef.sort === "function"
      ? sortColumnDef.sort
      : createDefaultSort(sortColumnDef.key);

  return (left, right) => {
    const ascSortResult = sortFn(left, right);
    return sortColumn.isDescending ? -ascSortResult : ascSortResult;
  };
}
