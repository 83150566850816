import { FunctionComponent } from "react";
import {
  DefaultButton,
  IButtonProps,
  Spinner,
  SpinnerSize,
  Stack
} from "@bps/fluent-ui";
import { ErrorAlert } from "../errors/ErrorAlert";

interface ApiCallButtonProps extends Omit<IButtonProps, "title"> {
  error: unknown;
  isLoading: boolean;
}
export const ApiCallButton: FunctionComponent<ApiCallButtonProps> = ({
  children,
  error,
  disabled,
  isLoading,
  ...rest
}) => {
  return (
    <Stack>
      <DefaultButton {...rest} disabled={disabled || isLoading}>
        {isLoading ? (
          <Stack horizontalAlign="center" verticalAlign="center">
            <div
              style={{
                color: "transparent",
                height: 1,
                overflow: "hidden",
                visibility: "hidden"
              }}
            >
              {children}
            </div>
            <Spinner size={SpinnerSize.medium} />
          </Stack>
        ) : (
          children
        )}
      </DefaultButton>
      <ErrorAlert styles={{ root: { width: "max-content" } }} error={error} />
    </Stack>
  );
};
