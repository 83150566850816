import { ComponentType, FunctionComponent } from "react";

import { TooltipHost, useFieldContext, useFieldRegister } from "@bps/fluent-ui";
import { UsersSelect, UsersSelectProps } from "../../pickers/UsersSelect";

interface UsersSelectFilterItemBaseProps
  extends Omit<
    UsersSelectProps,
    "selectedKeys" | "onChangeSelectedKeys" | "onFocus" | "onBlur"
  > {
  name: string;
}

interface UsersSelectFilterItemProps extends UsersSelectFilterItemBaseProps {
  dependOnTenantSelectName?: string;
}

const UsersSelectFilterItemBase: FunctionComponent<UsersSelectFilterItemBaseProps> = ({
  name,
  ...usersSelectProps
}) => {
  useFieldRegister(name);

  const { value, setFieldValue, focusField, blurField } = useFieldContext<
    string | undefined | string[]
  >(name);

  return (
    <UsersSelect
      {...usersSelectProps}
      onFocus={focusField}
      onBlur={blurField}
      selectedKeys={value as any}
      onChangeSelectedKeys={setFieldValue}
    />
  );
};

const withTenantIdField = (
  Component: ComponentType<UsersSelectFilterItemProps>
) => {
  const WithTenantIdField = (props: UsersSelectFilterItemProps) => {
    const { value } = useFieldContext(props.dependOnTenantSelectName!);
    return (
      <TooltipHost content={!value ? "Select tenant first" : undefined}>
        <Component {...props} tenantId={value as string} disabled={!value} />
      </TooltipHost>
    );
  };

  return WithTenantIdField;
};

export const UsersSelectFilterItem: FunctionComponent<UsersSelectFilterItemProps> = props => {
  const { dependOnTenantSelectName, ...usersSelectProps } = props;
  if (!dependOnTenantSelectName && !usersSelectProps.tenantId)
    throw Error("tenantId or dependOnTenantSelectName props should be passed");

  if (dependOnTenantSelectName) {
    return withTenantIdField(UsersSelectFilterItemBase)(props);
  }

  return (
    <UsersSelectFilterItemBase
      {...usersSelectProps}
      tenantId={usersSelectProps.tenantId!}
    />
  );
};
