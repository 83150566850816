import React, { FunctionComponent, useState } from "react";
import { FormTextField } from "./FormTextField";
import { FontIcon, TooltipHost, useTheme } from "@bps/fluent-ui";

const MIN_PIN_LENGTH = 6;
const MIN_PIN_VALIDATION_MESSAGE = "Pin code length should be 6 characters.";
const PIN_TOOLTIP = "Show pin";
const PIN_PLACEHOLDER = "6 characters pin code";
const PIN_LABEL = "PIN code";

export interface FormPinFieldProps {
  name: string;
}

export const FormPinField: FunctionComponent<FormPinFieldProps> = ({
  name
}) => {
  const theme = useTheme();
  const [showPin, setShowPin] = useState<boolean>(false);

  return (
    <FormTextField
      type={showPin ? "text" : "password"}
      label={PIN_LABEL}
      placeholder={PIN_PLACEHOLDER}
      name={name}
      max={MIN_PIN_LENGTH}
      min={MIN_PIN_LENGTH}
      styles={{ suffix: { padding: 0 } }}
      onRenderSuffix={() => (
        <TooltipHost content={PIN_TOOLTIP}>
          <FontIcon
            iconName="RedEye"
            onClick={() => setShowPin(prev => !prev)}
            styles={{
              root: {
                cursor: "pointer",
                padding: "10px 8px",
                color: showPin
                  ? theme.palette.themePrimary
                  : theme.palette.neutralSecondaryAlt
              }
            }}
          />
        </TooltipHost>
      )}
      rules={{
        minLength: {
          value: MIN_PIN_LENGTH,
          message: MIN_PIN_VALIDATION_MESSAGE
        },
        maxLength: {
          value: MIN_PIN_LENGTH,
          message: MIN_PIN_VALIDATION_MESSAGE
        }
      }}
    />
  );
};
