import { FilterBarStyles, FilterBarStylesProps } from "./FilterBar.types";
import { getFilterBarStyles as getBaseFilterBarStyles } from "@bps/fluent-ui";
export const getFilterBarStyles = ({
  theme
}: FilterBarStylesProps): FilterBarStyles => {
  const { select } = getBaseFilterBarStyles({ theme });
  return {
    select
  };
};
