import { Text, useTheme } from "@bps/fluent-ui";
import { PropsWithChildren } from "react";

export const SectionTitle = ({ children }: PropsWithChildren<unknown>) => {
  const theme = useTheme();

  return (
    <Text
      variant="xxLarge"
      styles={{ root: { color: theme.palette.themePrimary } }}
    >
      {children}
    </Text>
  );
};
