import { FunctionComponent, useEffect, useRef } from "react";

export type ScrollListenerProps = {
  /**
   * How long to wait before triggering onScroll related events. Calls are delayed for performance.
   * Default is 200ms.
   */
  delay?: number;
  /**
   * This is called when the scrollable element has been scrolled to the end (after the configured delay)
   */
  onScrolledToBottom?: () => Promise<void>;

  /**
   * Determine if we are at the end of a list.
   */
  hasNextPage: boolean;
};

const OFFSET_MARGIN = 300;

/**
 * ScrollListener exposes a callback that will be called when the associated
 * scrollable element is scrolled to the end.
 */
export const ScrollListener: FunctionComponent<ScrollListenerProps> = ({
  hasNextPage,
  onScrolledToBottom,
  delay
}) => {
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const ticking = useRef<boolean>(false);

  useEffect(() => {
    const scrollableElement = triggerRef.current?.closest(
      "*[data-is-scrollable='true']"
    );

    if (scrollableElement) {
      const handleOnScroll = () => {
        if (!ticking.current) {
          window.setTimeout(async () => {
            if (hasNextPage) {
              const offset =
                scrollableElement.scrollHeight -
                scrollableElement.scrollTop -
                scrollableElement.clientHeight;

              const isAtBottom = offset <= OFFSET_MARGIN;

              if (isAtBottom && onScrolledToBottom) {
                scrollableElement["style"].overflowY = "hidden";
                await onScrolledToBottom();
                scrollableElement["style"].overflowY = "auto";
              }
            }

            ticking.current = false;
          }, delay || 200);
        }
        ticking.current = true;
      };

      scrollableElement.addEventListener("scroll", handleOnScroll);

      return () => {
        scrollableElement.removeEventListener("scroll", handleOnScroll);
      };
    }

    return () => {};
  }, [delay, hasNextPage, onScrolledToBottom]);

  return <div ref={triggerRef} style={{ display: "none" }} />;
};
