import { FunctionComponent } from "react";
import { FormFieldProps } from "./FormFieldProps";
import { Controller, useFormContext } from "react-hook-form";
import {
  FieldItem,
  IconSelect,
  FieldItemStyleProps,
  OptionsSelectProps,
  IStyleFunctionOrObject,
  FieldItemStyles
} from "@bps/fluent-ui";

type FormUsersSelectProps = Omit<
  OptionsSelectProps,
  "selectedKeys" | "onChangeSelectedKeys" | "multiSelect"
> & {
  fieldItemStyles?: IStyleFunctionOrObject<
    FieldItemStyleProps,
    FieldItemStyles
  >;
} & FormFieldProps;

export const FormIconSelect: FunctionComponent<FormUsersSelectProps> = ({
  name,
  rules,
  fieldItemStyles,
  ...restUsersSelectProps
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { value, onChange, ref, ...fieldProps },
        fieldState: { error: validationError }
      }) => (
        <FieldItem
          name={name}
          errorMessage={validationError?.message}
          styles={fieldItemStyles}
        >
          <IconSelect
            selectedKeys={value}
            onChangeSelectedKeys={onChange}
            {...restUsersSelectProps}
            {...fieldProps}
          />
        </FieldItem>
      )}
    />
  );
};
