import { ApplicationType } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { FunctionComponent } from "react";

import { Option, OptionsSelect, OptionsSelectProps } from "@bps/fluent-ui";
import { useApplicationsRefData } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { RefDataDto } from "@libs/api/types/common-dtos";

export interface ApplicationsSelectProps
  extends Omit<OptionsSelectProps, "options"> {
  excludeCam?: boolean;
}

export const ApplicationsSelect: FunctionComponent<ApplicationsSelectProps> = props => {
  const { isLoading, error, data } = useApplicationsRefData();
  const applications = data ?? [];

  const options: Option<RefDataDto>[] = applications.map(data => ({
    key: data?.code,
    text: data?.text,
    data
  }));

  const filteredOptions = props.excludeCam
    ? options.filter(o => o.key !== ApplicationType.CAM)
    : options;

  return (
    <OptionsSelect
      options={filteredOptions}
      errorMessage={error?.message}
      loading={isLoading}
      hideSearchOption
      {...props}
    />
  );
};
