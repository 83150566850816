import { FunctionComponent, useState } from "react";

import { GenericSelect, Option, OptionsSelectProps } from "@bps/fluent-ui";

import { getPagesResult } from "@libs/paging/paging.utils";
import { ApplicationType } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useTenantsPltQuery } from "@libs/api/gateways/plt/plt-gateway.hooks";

export interface TenantPickerProps
  extends Pick<
    OptionsSelectProps,
    | "selectedKeys"
    | "onChangeSelectedKeys"
    | "label"
    | "onBlur"
    | "onFocus"
    | "placeholder"
  > {
  excludeKeys?: string[];
  applications?: ApplicationType[];
  country?: string;
}
export const TenantPicker: FunctionComponent<TenantPickerProps> = ({
  excludeKeys,
  applications,
  country,
  placeholder,
  ...restOptionsSelectProps
}) => {
  const [name, setName] = useState();
  const {
    isLoading,
    error,
    data,
    fetchNextPage,
    hasNextPage
  } = useTenantsPltQuery({
    isInactive: false,
    name,
    applications,
    countries: country ? [country] : []
  });

  const options: Option[] = getPagesResult(data)
    .map(d => ({ key: d.id, text: d.name }))
    .filter(d => {
      if (!excludeKeys || excludeKeys.length === 0) {
        return true;
      } else {
        return !excludeKeys.includes(d.key);
      }
    });

  return (
    <GenericSelect
      searchBoxProps={{ onSearch: setName }}
      options={options}
      loading={isLoading}
      errorMessage={error?.message}
      styles={{ root: { width: 250 } }}
      placeholder={placeholder ?? "Search by name"}
      showMoreOptionProps={{
        initialNumOfOptions: !hasNextPage ? options.length : options.length - 1,
        onClickShowMore: () => fetchNextPage()
      }}
      {...restOptionsSelectProps}
    />
  );
};
