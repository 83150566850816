import React from "react";

import { FontIcon, mergeStyles, useTheme } from "@bps/fluent-ui";

interface YesOrNoIconProps {
  when?: boolean;
}

export const YesOrNoIcon: React.FC<YesOrNoIconProps> = ({ when }) => {
  const theme = useTheme();
  return (
    <FontIcon
      iconName={when ? "CheckMark" : "Cancel"}
      className={mergeStyles({
        padding: 1,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: when
          ? theme.semanticColors.successIcon
          : theme.semanticColors.errorIcon,
        color: when
          ? theme.semanticColors.successIcon
          : theme.semanticColors.errorIcon
      })}
    />
  );
};
