import { FunctionComponent } from "react";

import { Option, OptionsSelect, OptionsSelectProps } from "@bps/fluent-ui";

import { ApplicationType } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useSecurityRolesAndPermissions } from "@libs/api/gateways/plt/plt-gateway.hooks";

import { SecurityRole } from "@libs/api/gateways/field/field-ops-gateway.dtos";

export interface SecurityRolesSelectProps
  extends Omit<OptionsSelectProps, "options" | "multiSelect"> {
  application?: ApplicationType;
}

export const SecurityRolesSelect: FunctionComponent<SecurityRolesSelectProps> = ({
  application,
  ...rest
}) => {
  const { isLoading, error, data } = useSecurityRolesAndPermissions();

  const startRole = application === ApplicationType.PROS ? "PROS" : application;

  const options: Option<SecurityRole>[] = (data?.securityRoles ?? [])
    .filter(p => {
      if (!startRole) return true;
      return p.code?.startsWith(startRole);
    })
    .map(data => ({
      key: data?.code ?? "No code returned.",
      text: data?.text ?? "No text returned.",
      disabled: !data?.code || !data.text,
      data
    }));

  return (
    <OptionsSelect
      options={options}
      errorMessage={error?.message}
      loading={isLoading}
      multiSelect
      {...rest}
    />
  );
};
