import { Breadcrumb, IBreadcrumbItem, Stack, useTheme } from "@bps/fluent-ui";

interface BreadcrumbProps {
  items: IBreadcrumbItem[];
}

export const Breadcrumbs = ({ items }: BreadcrumbProps) => {
  const theme = useTheme();
  return (
    <Stack
      styles={{
        root: {
          marginBottom: 16,
          backgroundColor: theme.palette.white
        }
      }}
    >
      <Breadcrumb
        styles={{
          root: {
            margin: 0
          }
        }}
        items={items}
      />
    </Stack>
  );
};
