import { SelectionList } from "@components/SelectionList";

export interface VerticalTab {
  id: string;
  name: string;
}

interface Props {
  title?: string;
  tabs: VerticalTab[];
  selectedTab?: string;
  onSelectedTabChange: (nextId: string) => void;
}

export const VerticalTabs = ({
  title,
  tabs,
  selectedTab,
  onSelectedTabChange
}: Props) => {
  const selectedItem = tabs.find(t => selectedTab?.includes(t.id));
  const handleSelectedItemChange = (next: VerticalTab) =>
    onSelectedTabChange(next.id);

  return (
    <SelectionList
      title={title}
      items={tabs}
      selectedItem={selectedItem}
      onSelectedItemChange={handleSelectedItemChange}
    />
  );
};
