import { FunctionComponent } from "react";
import {
  MessageBar,
  MessageBarType,
  // eslint-disable-next-line no-restricted-imports
  ShimmeredDetailsList as ShimmeredDetailsListBase,
  ShimmeredDetailsListProps as ShimmeredDetailsListPropsBase
} from "@bps/fluent-ui";

interface ShimmeredDetailsListProps
  extends Omit<ShimmeredDetailsListPropsBase, "onRenderError"> {}

export const ShimmeredDetailsList: FunctionComponent<ShimmeredDetailsListProps> = props => {
  return (
    <ShimmeredDetailsListBase
      {...props}
      onRenderError={error => (
        <MessageBar messageBarType={MessageBarType.error}>{error}</MessageBar>
      )}
    />
  );
};
