import { toTitleCase } from "@libs/common/common.utils";

import { IBreadcrumbItem } from "@bps/fluent-ui";

import { Tabs } from "@components/tabs-nav/TabNav.types";

export const getBreadcrumbTabName = (tabs: Tabs, tabId: string) => {
  for (const parentKey in tabs) {
    if (parentKey === tabId) {
      return parentKey;
    }
    for (const tabKey in tabs[parentKey]) {
      const tab = tabs[parentKey][tabKey];
      if (tab.id === tabId) {
        return tab.name;
      }
    }
  }

  switch (tabId) {
    case "env":
      return "Environment";
    case "plt":
      return "Platform";
    case "bh":
      return "Best Health";
    case "field-mgmt":
      return "Field Management";
    case "cam":
      return "Customers";
    case "omni":
      return "Omni";
  }
  return tabId;
};

export const getDefaultBreadcrumbItems = (tabs: Tabs, pathname: string) => {
  const parts = pathname.slice(1).split("/");
  const _items: IBreadcrumbItem[] = [];
  for (const part of parts) {
    const desc = getBreadcrumbTabName(tabs, part);
    _items.push({
      key: part,
      text: desc === part ? toTitleCase(part) : desc
    });
  }
  return _items;
};
