import humanizer from "@libs/common/humanize-duration";
import { HumanizeDurationOptions } from "humanize-duration-ts";

import { TooltipHost } from "@bps/fluent-ui";
import { DATE_FORMATS, DateTime } from "@bps/utils";

const humanizeOptions = {
  units: ["d", "h", "m"],
  round: true
} as HumanizeDurationOptions;

interface DateTimeWithDurationSinceTooltipProps {
  dateUtc: DateTime | undefined;
}

export const DateTimeWithDurationSinceTooltip = ({
  dateUtc
}: DateTimeWithDurationSinceTooltipProps) => {
  if (!dateUtc) return null;

  const now = DateTime.now();
  const humanizedTooltip = humanizer.humanize(
    dateUtc.diff(now).milliseconds,
    humanizeOptions
  );

  const tooltipText = `${humanizedTooltip} ago`;

  return (
    <TooltipHost content={tooltipText}>
      {dateUtc.toFormat(DATE_FORMATS.LONG_DATE_FORMAT_MONTH_AHEAD_CUT)}
    </TooltipHost>
  );
};
