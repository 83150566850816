import React, { useMemo, useState } from "react";

import {
  DropdownProps,
  SelectableOptionMenuItemType,
  Dropdown,
  IDropdownOption
} from "@bps/fluent-ui";
import {
  useApprovedComponentVersionsQuery,
  useAvailableComponentVersionsQuery
} from "@libs/api/gateways/sia/sia-ops-gateway.hooks";
import {
  SiteComponentType,
  SiteComponentVersionApproval
} from "@libs/api/gateways/sia/sia-ops-gateway.dtos";
import { SiteComponentVersion } from "@libs/api/gateways/sia/models/SiteComponentVersion";

interface SiteComponentVersionDropdownProps
  extends Omit<DropdownProps, "options" | "name" | "onChange"> {
  onChange?: (item: IDropdownOption | undefined) => void;
  componentType?: SiteComponentType;
}

const DEFAULT_VERSION_COUNT = 10;

const showAllDropDownOption: IDropdownOption = {
  key: "showAll",
  text: "(Show all...)"
};
export const SiteComponentVersionDropdown: React.FC<SiteComponentVersionDropdownProps> = ({
  componentType,
  onChange,
  ...props
}) => {
  const [showAll, setShowAll] = useState(false);
  const { data: approvedVersions } = useApprovedComponentVersionsQuery();
  const handleVersionChange: DropdownProps["onChange"] = (
    _event,
    item
  ): void => {
    if (item?.key === showAllDropDownOption.key) {
      setShowAll(true);
    } else {
      onChange?.(item);
    }
  };

  const {
    data: allVersionsByComponent = []
  } = useAvailableComponentVersionsQuery();

  // Approved Versions
  const approvedVersionOptions = useMemo(() => {
    if (!approvedVersions) return [];

    const sortByCreated = (
      a: SiteComponentVersionApproval,
      b: SiteComponentVersionApproval
    ) => {
      const componentVersions = allVersionsByComponent.find(
        x => x.component === componentType
      );

      if (componentVersions === undefined) return 0;

      const versionA = componentVersions.versions.find(
        v => v.version === a.componentVersion
      );

      const versionB = componentVersions.versions.find(
        v => v.version === b.componentVersion
      );

      if (versionA === undefined || versionB === undefined) return 0;
      if (versionA?.createdDate > versionB?.createdDate) return -1;
      return versionA?.createdDate > versionB?.createdDate ? 1 : 0;
    };

    return approvedVersions
      .filter(version => {
        return version.componentType === componentType;
      })
      .sort(sortByCreated)
      .map(version => {
        return {
          key: version.componentVersion,
          text: version.componentVersion
        };
      })
      .slice(0, DEFAULT_VERSION_COUNT);
  }, [allVersionsByComponent, approvedVersions, componentType]);

  // Recent Versions
  const recentVersionOptions = useMemo(() => {
    if (!allVersionsByComponent) return [];

    // Map to dropdown object
    const versions = allVersionsByComponent.map(
      dto => new SiteComponentVersion(dto)
    );

    const componentVersions = versions.find(x => x.component === componentType);

    if (!componentVersions) return [];

    return showAll
      ? componentVersions.versions
      : [
          ...componentVersions.versions
            .slice(0, DEFAULT_VERSION_COUNT)
            .filter(
              version =>
                !approvedVersionOptions.find(v => v.key === version.key)
            )
        ];
  }, [allVersionsByComponent, showAll, componentType, approvedVersionOptions]);

  // Get versions custom dropdown options
  const getVersions = (): IDropdownOption[] => {
    const allVersionOptions: IDropdownOption[] = [];
    if (componentType !== undefined) {
      if (approvedVersions !== undefined) {
        allVersionOptions.push(
          ...[
            {
              key: "Header1",
              text: "Approved Versions",
              itemType: SelectableOptionMenuItemType.Header
            },
            ...approvedVersionOptions,
            {
              key: "divider_1",
              text: "-",
              itemType: SelectableOptionMenuItemType.Divider
            }
          ]
        );
      }
      allVersionOptions.push(
        ...[
          {
            key: "Header2",
            text: "Recent Versions",
            itemType: SelectableOptionMenuItemType.Header
          },
          ...recentVersionOptions
        ]
      );
    }
    return allVersionOptions;
  };

  return (
    <Dropdown
      name="name"
      onChange={handleVersionChange}
      {...props}
      disabled={componentType === undefined}
      options={getVersions()}
    />
  );
};
