import { FormFieldProps } from "@components/form/fields/FormFieldProps";
import { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  DatePicker,
  DatePickerProps,
  FieldItem,
  mergeStyles,
  useTheme
} from "@bps/fluent-ui";

// DatePickerProps has a 'name' property but it's optional; for usage with react-hook-form it needs to be mandatory.
type FormDatePickerProps = Omit<
  DatePickerProps,
  "name" | "value" | "onChange"
> &
  FormFieldProps & {
    disabled?: boolean;
    label?: string;
    required?: boolean;
  };

export const FormDatePicker: React.FC<FormDatePickerProps> = ({
  name,
  rules,
  disabled: isDisabled,
  label,
  required,
  ...props
}) => {
  const { control, formState } = useFormContext();
  const disabled = isDisabled || formState.isSubmitting;
  const theme = useTheme();

  const hasErrorClassName = useMemo(
    () =>
      mergeStyles({
        "& .ms-TextField-fieldGroup": {
          borderColor: theme.semanticColors.errorText
        }
      }),
    [theme]
  );

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({
        field: { value, onChange, ref, ...fieldProps },
        fieldState: { error }
      }) => {
        return (
          <FieldItem name={name} errorMessage={error?.message}>
            <DatePicker
              {...props}
              {...fieldProps}
              value={value}
              onChange={onChange}
              textFieldProps={{
                disabled,
                label,
                required
              }}
              className={error?.message && hasErrorClassName}
            />
          </FieldItem>
        );
      }}
    />
  );
};
