import React from "react";

export interface TabNavItemDetails<D extends unknown = any> {
  id: string;
  name?: string;
  sectionId?: string;
  data?: D;
}

export interface TabsNavLinkProps<D extends unknown = any> {
  details: TabNavItemDetails<D>;
  isSelected: boolean;
}

export interface TabsNavProps<D extends any> {
  navigatePath: (details: TabNavItemDetails<D>) => string;
  defaultPath: string;
  tabKey: string;
  renderTab?: (
    details: TabNavItemDetails<D>,
    setSectionId: (id: string) => void
  ) => React.ReactNode;
  renderDefault?: () => React.ReactNode;
  renderLink?: (props: TabsNavLinkProps<D>) => React.ReactNode;
  defaultTabName?: string;
  selectedTab?: string;
  defaultTabLeft?: boolean;
}

export type Tabs = Record<
  string,
  Record<string, { id: string; name: string; applicationRestriction?: string }>
>;

export const DEFAULT_ITEM_KEY = "default";
