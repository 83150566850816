import { Text, TextProps } from "@bps/fluent-ui";

type Props = Omit<TextProps, "children"> & {
  short?: boolean;
  children: string;
};

export const DisplayGuid = ({ short, children, ...textProps }: Props) => {
  const displayText = short
    ? `${children.substring(0, 4)}...${children.slice(-4)}`
    : children;

  return <Text {...textProps}>{displayText}</Text>;
};
