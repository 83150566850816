import { FunctionComponent } from "react";
import { useController } from "react-hook-form";

import {
  ProductsSelect,
  ProductsSelectProps
} from "../../pickers/ProductsSelect";
import { FormFieldProps } from "./FormFieldProps";

type FormProductsSelectProps = Omit<
  ProductsSelectProps,
  "selectedKeys" | "onChangeSelectedKeys"
> &
  FormFieldProps;

export const FormProductsSelect: FunctionComponent<FormProductsSelectProps> = ({
  name,
  rules,
  ...restProductsSelectProps
}) => {
  const {
    field: { value, onChange, ref, ...fieldProps }
  } = useController({ name, rules });

  return (
    <ProductsSelect
      selectedKeys={value}
      onChangeSelectedKeys={onChange}
      {...restProductsSelectProps}
      {...fieldProps}
    />
  );
};
