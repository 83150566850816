import { useRootStore } from "@stores/StoresProvider";
import { observer } from "mobx-react";
import { MessageBar, MessageBarType, Stack } from "@bps/fluent-ui";
import { NotificationType } from "@stores/FeedbackStore";

export const Notifications = observer(() => {
  const { feedback } = useRootStore();

  return (
    <Stack
      styles={{
        root: { position: "absolute", right: 260, top: 8, zIndex: 1000001 }
      }}
    >
      {feedback.notifications.map(x => (
        <MessageBar
          key={x.key}
          messageBarType={toMessageBarType(x.type)}
          dismissButtonAriaLabel="Close"
          onDismiss={() => {
            feedback.close(x.key);
          }}
        >
          {x.message}
        </MessageBar>
      ))}
    </Stack>
  );
});

const toMessageBarType = (notificationType: NotificationType) => {
  switch (notificationType) {
    case "error":
      return MessageBarType.error;
    case "info":
      return MessageBarType.info;
    case "success":
      return MessageBarType.success;
    case "warning": {
      return MessageBarType.warning;
    }
  }
};
