import {
  ILabelStyleProps,
  ILabelStyles,
  metaToLabelColor,
  IStyleFunctionOrObject
} from "@bps/fluent-ui";
import { FieldError } from "react-hook-form";

export const getLabelStyles = (meta: {
  active?: boolean;
  error?: FieldError;
}): IStyleFunctionOrObject<ILabelStyleProps, ILabelStyles> => props => {
  return {
    root: {
      color: metaToLabelColor(
        {
          active: meta.active,
          hasErrorMessage: !!meta.error?.message
        },
        props.theme
      )
    }
  };
};
