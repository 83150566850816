import { FunctionComponent } from "react";
import { FormFieldProps } from "./FormFieldProps";
import { useController } from "react-hook-form";

import {
  TenantPromotionCodeSelect,
  TenantPromotionCodeSelectProps
} from "../../pickers/TenantPromotionCodeSelect";

type FormTenantPromotionCodeSelectProps = Omit<
  TenantPromotionCodeSelectProps,
  "selectedKeys" | "onChangeSelectedKeys"
> &
  FormFieldProps;

export const FormTenantPromotionCodeSelect: FunctionComponent<FormTenantPromotionCodeSelectProps> = ({
  name,
  rules,
  ...rest
}) => {
  const {
    field: { value, onChange, ref, ...fieldProps }
  } = useController({ name, rules });

  return (
    <TenantPromotionCodeSelect
      selectedKeys={value}
      onChangeSelectedKeys={onChange}
      {...rest}
      {...fieldProps}
    />
  );
};
