import { FunctionComponent, useMemo } from "react";

import {
  FilterBar as BaseFilterBar,
  classNamesFunction,
  CustomOptionType,
  styled,
  useTheme
} from "@bps/fluent-ui";
import { getFilterBarStyles } from "./FilterBar.styles";
import {
  FilterBarProps,
  FilterBarStyles,
  FilterBarStylesProps
} from "./FilterBar.types";
import { UsersSelectFilterItem } from "./custom-items/UsersSelectFilterItem";
import { TenantsSelectFilterItem } from "./custom-items/TenantsSelectFilterItem";

const getClassNames = classNamesFunction<
  FilterBarStylesProps,
  FilterBarStyles
>();

/**
 * @bps/fluent-ui FilterBar instance with usersSelect custom option.
 * @param props
 */
export const FilterBarBase: FunctionComponent<FilterBarProps> = props => {
  const theme = useTheme();
  const classNames = getClassNames(props.styles, {
    theme
  });

  const customOptions: CustomOptionType[] = useMemo(() => {
    return [
      {
        type: "usersSelect",
        Component: UsersSelectFilterItem,
        className: classNames.select
      },
      {
        type: "tenantsSelect",
        Component: TenantsSelectFilterItem,
        className: classNames.select
      }
    ];
  }, [classNames.select]);

  return (
    <BaseFilterBar
      {...props}
      items={props.items as any}
      customOptions={customOptions}
    />
  );
};

const UntypedFilterBar = styled<
  FilterBarProps,
  FilterBarStylesProps,
  FilterBarStyles
>(FilterBarBase, getFilterBarStyles);

export const FilterBar = <FormValues extends object = object>(
  props: FilterBarProps<FormValues>
) => <UntypedFilterBar {...props} />;
