import {
  CopyToClipboardButton,
  Stack,
  Text,
  TooltipHost
} from "@bps/fluent-ui";

const MAX_GUID_CHARS_SHOWN = 13;

interface TableGuidDisplayProps {
  id: string | undefined;
}

export const TableGuidDisplay = ({ id }: TableGuidDisplayProps) => {
  if (!id) return null;

  return (
    <Stack horizontal horizontalAlign="space-between">
      <TooltipHost content={id}>
        <Text>{id.substring(0, MAX_GUID_CHARS_SHOWN)}...</Text>
      </TooltipHost>
      <CopyToClipboardButton
        clipboardContent={id}
        buttonProps={{
          styles: {
            root: {
              height: "20px"
            }
          }
        }}
      />
    </Stack>
  );
};
