import { FunctionComponent } from "react";
import { Option, OptionsSelect, OptionsSelectProps } from "@bps/fluent-ui";
import { useTenantPromotionCodes } from "@libs/api/gateways/plt/plt-gateway.hooks";
import { PromotionCodeDto } from "@libs/api/gateways/plt/plt-gateway.dtos";

export interface TenantPromotionCodeSelectProps
  extends Omit<OptionsSelectProps, "options" | "multiSelect"> {
  tenantId: string;
  withNotSelectedOption?: boolean;
}

export const NONE_KEY = "NONE";

export const TenantPromotionCodeSelect: FunctionComponent<TenantPromotionCodeSelectProps> = ({
  tenantId,
  withNotSelectedOption,
  ...rest
}) => {
  const { isLoading, error, data = [] } = useTenantPromotionCodes(tenantId);

  const options: Option<PromotionCodeDto>[] = data
    .filter(code => code.active)
    .map(data => ({
      key: data?.code,
      text: data.code,
      data
    }));

  if (withNotSelectedOption) {
    options.unshift({ key: "NONE", text: "No code required" });
  }

  return (
    <OptionsSelect
      options={options}
      errorMessage={error?.message}
      loading={isLoading}
      placeholder="Select promotion code"
      {...rest}
    />
  );
};
