import { CenteredBox } from "@bps/fluent-ui";
import { NO_RESOURCE_PERMISSION } from "@libs/permissions/permissions.constants";

export const NoPermissionError = () => {
  return (
    <CenteredBox>
      <div>
        <p>{NO_RESOURCE_PERMISSION}</p>
      </div>
    </CenteredBox>
  );
};
