import { FunctionComponent } from "react";
import {
  Spinner,
  SpinnerSize,
  TextBadge,
  TextBadgeColor,
  TextBadgeSize
} from "@bps/fluent-ui";
import { ApplicationType } from "@libs/api/gateways/plt/plt-gateway.dtos";
import { useApplicationsRefData } from "@libs/api/gateways/plt/plt-gateway.hooks";

interface ApplicationBadgeProps {
  code: ApplicationType | undefined;
  badgeSize?: TextBadgeSize;
}

const getApplicationBadgeColour = (
  applicationCode: ApplicationType | undefined
) => {
  if (applicationCode === ApplicationType.CAM) return TextBadgeColor.green;
  if (applicationCode === ApplicationType.PROS) return TextBadgeColor.blue;
  if (applicationCode === ApplicationType.OMNI) return TextBadgeColor.yellow;
  if (applicationCode === ApplicationType.BPID) return TextBadgeColor.lightGrey;
  return TextBadgeColor.red;
};

export const ApplicationBadge: FunctionComponent<ApplicationBadgeProps> = ({
  code,
  badgeSize = TextBadgeSize.small
}) => {
  const { data = [], isLoading } = useApplicationsRefData();
  const applicationData = data?.find(app => app.code === code);
  const text = applicationData?.text ?? "Not found";

  return isLoading ? (
    <Spinner size={SpinnerSize.xSmall} />
  ) : (
    <TextBadge
      badgeColor={getApplicationBadgeColour(code)}
      badgeSize={badgeSize}
      styles={{ root: { width: "fit-content" } }}
    >
      {text}
    </TextBadge>
  );
};
