import { TextBadge, TextBadgeColor, TextBadgeSize } from "@bps/fluent-ui";
import { Duration } from "@bps/utils";

export const DurationText = ({ duration }: { duration?: number }) => {
  let color = TextBadgeColor.lightGrey;
  let text: string | undefined;

  if (duration != null) {
    const durationLux = Duration.fromMillis(duration).shiftTo(
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    );

    const durationObj = durationLux.toObject();

    if (durationObj.days) {
      text = durationLux.toFormat("d'd' h'h'");
    } else if (durationObj.hours) {
      text = durationLux.toFormat("h'h' m'm'");
    } else if (durationObj.minutes) {
      text = durationLux.toFormat("m'm' s's'");
    } else if (durationObj.seconds) {
      text = durationLux.toFormat("s's' S'ms'");
    } else {
      text = durationLux.toFormat("S'ms'");
    }

    if (duration > 60_000) {
      color = TextBadgeColor.red;
    } else if (duration > 1_000) {
      color = TextBadgeColor.yellow;
    } else {
      color = TextBadgeColor.green;
    }
  }

  return (
    <TextBadge
      badgeColor={color}
      badgeSize={TextBadgeSize.small}
      styles={{ root: { width: "fit-content" } }}
    >
      {text ?? "--"}
    </TextBadge>
  );
};
