import {
  Callout,
  getId,
  ICalloutProps,
  IconButton,
  Stack,
  Text,
  useTheme
} from "@bps/fluent-ui";
import { useEffect, useRef } from "react";
import { useBoolean } from "react-use";

interface Props extends Omit<ICalloutProps, "target" | "onDismiss" | "hidden"> {
  tooltip?: string;
  confirmText?: string;
  clipboardContent: string;
}

export const CopyToClipboardButton = ({
  tooltip,
  confirmText,
  clipboardContent,
  ...calloutProps
}: Props) => {
  const theme = useTheme();
  const copyButtonId = getId("url-tooltip");
  const [copied, toggleCopied] = useBoolean(false);

  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  const handleClick = evt => {
    evt.stopPropagation();
    navigator.clipboard.writeText(clipboardContent).then(() => {});
    toggleCopied(true);

    timeoutRef.current = setTimeout(() => toggleCopied(false), 3000);
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Stack horizontal>
      <Callout
        gapSpace={0}
        {...calloutProps}
        target={`#${copyButtonId}`}
        hidden={!copied}
      >
        <Text
          block
          variant="small"
          styles={{ root: { padding: theme.spacing.s1 } }}
        >
          {confirmText ?? "Copied!"}
        </Text>
      </Callout>

      <IconButton
        id={copyButtonId}
        title={tooltip}
        ariaLabel={tooltip}
        iconProps={{ iconName: "Copy" }}
        onClick={handleClick}
      />
    </Stack>
  );
};

CopyToClipboardButton.defaultProps = {
  tooltip: "Copy to clipboard"
};
