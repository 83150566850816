import React, { useMemo } from "react";

import { MessageBar, MessageBarType, useTheme } from "@bps/fluent-ui";

import { config } from "@libs/config/config";

export const EnvName: React.FC = () => {
  const theme = useTheme();

  const envType = useMemo<MessageBarType>(() => {
    switch (config.env) {
      default:
        return MessageBarType.severeWarning;
      case "uat":
        return MessageBarType.warning;
      case "dev":
        return MessageBarType.success;
    }
  }, []);

  return (
    <MessageBar
      messageBarType={envType}
      styles={{
        root: { marginLeft: theme.spacing.l1, width: "fit-content" }
      }}
    >
      {config.env.toUpperCase()}
    </MessageBar>
  );
};
