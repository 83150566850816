import { FunctionComponent } from "react";
import { DATE_FORMATS, DateTime } from "@bps/utils";
import { Text } from "@bps/fluent-ui";

interface TimeTextProps {
  time: DateTime | undefined;
}
export const TimeText: FunctionComponent<TimeTextProps> = ({ time }) => {
  return (
    <Text>
      {time?.toFormat(DATE_FORMATS.LONG_DATE_FORMAT_MONTH_AHEAD_CUT) ?? "--"}
    </Text>
  );
};
