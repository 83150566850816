import { Permissions } from "@libs/permissions/permissions.enum";
import { usePermissionsCheckQuery } from "@libs/api/gateways/env/env-gateway.hooks";

import { Spinner } from "@bps/fluent-ui";

import { NoPermissionError } from "./errors/NoPermissionError";

export const withPermissions = <P extends object>(
  Component: React.ComponentType<P>,
  permissions: Permissions | Permissions[],
  permissionOperator?: "and" | "or"
) => {
  return (props: P) => {
    const { data: hasPermissions, isLoading } = usePermissionsCheckQuery(
      permissions,
      permissionOperator
    );

    if (isLoading) {
      return <Spinner />;
    }

    if (hasPermissions) {
      return <Component {...(props as P)} />;
    }

    return <NoPermissionError />;
  };
};
