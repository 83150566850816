import { Permissions } from "@libs/permissions/permissions.enum";
import React from "react";

import { Link, mergeStyles, Stack, Text, useTheme } from "@bps/fluent-ui";
import { verifyHasPermissions } from "@libs/permissions/permissions.utils";
import { useCurrentUserQuery } from "@libs/api/gateways/env/env-gateway.hooks";

export interface Item {
  id: string;
  name: string;
  node?: React.ReactNode;
  disabled?: boolean;
  requiredPermissions?: Permissions | Permissions[];
}

interface Props {
  title?: string;
  items: Item[];
  selectedItem?: Item;
  onSelectedItemChange: (item: Item) => void;
}

export const SelectionList = ({
  title,
  items,
  selectedItem,
  onSelectedItemChange
}: Props) => {
  const theme = useTheme();
  const { data: user } = useCurrentUserQuery();
  const userPermissions = user?.impersonatingRole
    ? user.impersonatingRole.permissions
    : user?.permissions || [];

  const linkStyles = mergeStyles({
    padding: theme.spacing.s1,

    "&:focus, &:hover, &:active, &:hover:active": {
      backgroundColor: theme.palette.neutralLighter,
      textDecoration: "none"
    }
  });

  const selectedStyles = mergeStyles(linkStyles, {
    "&, &:hover, &:focus, &:active, &:hover:active": {
      backgroundColor: theme.palette.neutralLight
    }
  });

  const handleSelectionChange = (next: Item) => () =>
    onSelectedItemChange(next);

  const filteredItems = items.filter(item =>
    verifyHasPermissions(userPermissions, item.requiredPermissions)
  );

  if (!filteredItems.length) {
    return <></>;
  }

  return (
    <Stack tokens={{ childrenGap: theme.spacing.s1 }}>
      {title && <Text variant="large">{title}</Text>}
      <Stack tokens={{ childrenGap: theme.spacing.s2 }}>
        {filteredItems.map(item => (
          <Link
            as="div"
            key={item.id}
            disabled={item?.disabled || false}
            onClick={handleSelectionChange(item)}
            className={item === selectedItem ? selectedStyles : linkStyles}
          >
            {item.node || item.name}
          </Link>
        ))}
      </Stack>
    </Stack>
  );
};
