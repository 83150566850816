import { Outlet, useLocation } from "react-router-dom";
import { FunctionComponent, useMemo } from "react";
import { IBreadcrumbItem } from "@bps/fluent-ui";
import { getDefaultBreadcrumbItems } from "@components/breadcrumbs/breadcrumbs.utils";
import { Breadcrumbs } from "@components/breadcrumbs/Breadcrumbs";
import { Tabs } from "@components/tabs-nav/TabNav.types";

interface DefaultBreadcrumbsProps {
  tabs: Tabs;
}
export const DefaultBreadcrumbs: FunctionComponent<DefaultBreadcrumbsProps> = ({
  tabs
}) => {
  const { pathname } = useLocation();

  const items = useMemo<IBreadcrumbItem[]>(() => {
    return getDefaultBreadcrumbItems(tabs, pathname);
  }, [pathname, tabs]);

  return (
    <>
      <Breadcrumbs items={items} />
      <Outlet />
    </>
  );
};
