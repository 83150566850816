import { FunctionComponent } from "react";
import { FormFieldProps } from "./FormFieldProps";
import { useController } from "react-hook-form";
import {
  ApplicationsSelect,
  ApplicationsSelectProps
} from "../../pickers/ApplicationsSelect";

type FormApplicationsSelectProps = Omit<
  ApplicationsSelectProps,
  "selectedKeys" | "onChangeSelectedKeys"
> &
  FormFieldProps;

export const FormApplicationsSelect: FunctionComponent<FormApplicationsSelectProps> = ({
  name,
  rules,
  ...restUsersSelectProps
}) => {
  const {
    field: { value, onChange, ref, ...fieldProps }
  } = useController({ name, rules });

  return (
    <ApplicationsSelect
      selectedKeys={value}
      onChangeSelectedKeys={onChange}
      {...restUsersSelectProps}
      {...fieldProps}
    />
  );
};
