import { useController } from "react-hook-form";

import { DirectionalHint, Dropdown, DropdownProps } from "@bps/fluent-ui";
import { FormFieldProps } from "@components/form/fields/FormFieldProps";

type FormDropdownProps = Omit<
  DropdownProps,
  "name" | "selectedKey" | "selectedKeys"
> &
  FormFieldProps;

export const FormDropdown = (props: FormDropdownProps) => {
  const { name, disabled, rules, multiSelect, ...dropdownProps } = props;

  const {
    field: { value, onChange, ref, ...fieldProps },
    fieldState,
    formState
  } = useController({ name, rules });

  const handleChange = (event, option) => {
    if (!multiSelect) {
      onChange(option.key);
    } else {
      const prev = (value || []) as string[];
      const next = prev.filter(key => key !== option.key);
      if (prev.length === next.length) {
        next.push(option.key);
      }

      onChange(next);
    }

    props.onChange && props.onChange(event, option);
  };

  const selectedKey = !multiSelect ? value : undefined;
  const selectedKeys = multiSelect ? value : undefined;

  const isDisabled = disabled || formState.isSubmitting;

  return (
    <Dropdown
      withNoEmptyOption
      {...dropdownProps}
      {...fieldProps}
      multiSelect={multiSelect}
      selectedKey={selectedKey}
      selectedKeys={selectedKeys}
      onChange={handleChange}
      disabled={isDisabled}
      errorMessage={fieldState.error?.message}
      calloutProps={{
        calloutMaxHeight: 300,
        directionalHint: DirectionalHint.bottomCenter
      }}
    />
  );
};
