import { FormFieldProps } from "@components/form/fields/FormFieldProps";
import { Controller, useFormContext } from "react-hook-form";

import { Checkbox, ICheckboxProps } from "@bps/fluent-ui";

// ICheckboxProps has a 'name' property but it's optional; for usage with react-hook-form it needs to be mandatory.
type FormCheckboxProps = Omit<ICheckboxProps, "name" | "automationAttribute"> &
  FormFieldProps;

export const FormCheckbox: React.FC<FormCheckboxProps> = ({
  name,
  disabled,
  rules,
  ref,
  ...props
}) => {
  const { control, formState } = useFormContext();
  const isDisabled = disabled || formState.isSubmitting;

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, onChange, ...fieldProps } }) => (
        <Checkbox
          automationAttribute={name}
          {...props}
          {...fieldProps}
          checked={value}
          onChange={(_, option) => {
            onChange(option);
          }}
          disabled={isDisabled}
        />
      )}
    />
  );
};
