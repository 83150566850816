import React, { FunctionComponent } from "react";
import { useForm, UseFormReturn, useFormState } from "react-hook-form";

import {
  DefaultButton,
  IStackStyles,
  mergeStyleSets,
  PrimaryButton,
  Spinner,
  SpinnerSize,
  Stack
} from "@bps/fluent-ui";

export interface SubmitFormButtonsProps {
  cancelButtonText?: string;
  submitButtonText?: string;
  hideSubmitButton?: boolean;
  allowSubmitOnClean?: boolean;
  showCancelButton?: boolean;
  onCancel?: (form: UseFormReturn) => void;
  buttonsRootStyles?: IStackStyles;
  hideError?: boolean;
}

export const SubmitFormButtons: FunctionComponent<SubmitFormButtonsProps> = ({
  submitButtonText = "Save",
  cancelButtonText = "Cancel",
  hideSubmitButton,
  showCancelButton,
  onCancel,
  buttonsRootStyles,
  allowSubmitOnClean
}) => {
  const formState = useFormState();
  const form = useForm();
  return (
    <Stack tokens={{ childrenGap: 8 }}>
      <Stack
        horizontal
        tokens={{ childrenGap: 8 }}
        styles={mergeStyleSets(
          { root: { alignSelf: "end" } },
          buttonsRootStyles
        )}
      >
        {showCancelButton && (
          <DefaultButton onClick={() => onCancel && onCancel(form)}>
            {cancelButtonText}
          </DefaultButton>
        )}
        {!hideSubmitButton && (
          <PrimaryButton
            styles={{ root: { alignSelf: "end" } }}
            disabled={
              (!allowSubmitOnClean && !formState.isDirty) ||
              formState.isSubmitting
            }
            type="submit"
            iconProps={{ iconName: "Save" }}
          >
            {formState.isSubmitting ? (
              <Spinner size={SpinnerSize.small} />
            ) : (
              submitButtonText
            )}
          </PrimaryButton>
        )}
      </Stack>
    </Stack>
  );
};
